<template>
    <BasicModal
        :show="isAddModalOpen"
        @close="cancelAdd"
        hideCloseButton
    >
        <AddUserForm
            :source="userToAdd"
            :isEditing="true"
            :isLoading="isLoading"
            :debug="isDebug"
            @cancel="cancelAdd"
            @submit="submitAdd"
        />
    </BasicModal>
    <BasicModal
        :show="isEditModalOpen"
        @close="cancelEdit"
        hideCloseButton
    >
        <EditUserForm
            :source="userToEdit"
            :isEditing="true"
            :isLoading="isLoading"
            :debug="isDebug"
            @cancel="cancelEdit"
            @submit="submitEdit"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete User"
        confirmationText="Are you sure you want to delete this user? This action cannot be undone."
        :open="isDeleteModalOpen"
        @close="cancelDelete"
        @cancel="cancelDelete"
        @delete="submitDelete"
    />
    <ConfirmResetPasswordModal
        title="Reset User's Password"
        confirmationText="Are you sure you want to reset this user's password?"
        :open="isResetModalOpen"
        @close="cancelReset"
        @cancel="cancelReset"
        @submit="submitReset"
    />
    <AdminContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        Edit, delete, and add users. To reset a user’s password
                        click on the lock icon.
                    </PageDescription>
                    <div
                        class="ml-2 grid grid-cols-1 w-full sm:w-2/5"
                        :class="maxUsersExceeded == true ? 'pb-0' : 'pb-2'"
                    >
                        <div class="grid grid-cols-1">
                            <div>
                                <ModalButton
                                    :disabled="isAddUserDisabled"
                                    theme="primary"
                                    label="Add User"
                                    :busy="isLoading"
                                    @click.prevent="clickAddButton"
                                    class="float-right"
                                />
                            </div>
                            <div class="col-span-1">
                                <div v-if="isNARADisabled">
                                    <p
                                        class="text-center sm:text-right text-sm text-gray-600"
                                    >
                                        This subscription is using
                                        {{ numUsers }} out of
                                        {{ maxUsers }} users.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageIntro>
                <div>
                    <p
                        v-if="maxUsersExceeded"
                        class="text-center sm:text-right text-xs text-red-500 mb-2"
                    >
                        Your organization is using the maximum number of users
                        available. Upgrade to a higher subscription level to
                        increase your user quota.
                    </p>
                </div>
                <LoadingWrapper :isLoading="isLoading">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="pagination.enabled"
                        :paginationPageSize="pagination.size"
                        :columnDefs="colDefs"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                        overlayNoRowsTemplate="No users to display."
                    />
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </AdminContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="alerts.state.alerts.value"
            @dismiss:alert="alerts.methods.dismissAlert($event)"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, provide } from 'vue';
    import { computedEager } from '@vueuse/core';

    // <!-- COMPOSABLES -->
    import { useNARAFeature } from '@/utils/features/';
    import { useExpiredSubscriptionGate } from '@/hooks/gates';

    // <!-- COMPONENTS -->
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import PageWrapper from '@/components/PageWrapper.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import ConfirmResetPasswordModal from '~UserManager/components/ConfirmResetPasswordModal.vue';
    import AdminContainer from '~Admin/components/wrappers/AdminContainer.vue';
    import AddUserForm from '~UserManager/components/AddUserForm.vue';
    import EditUserForm from '~UserManager/components/EditUserForm.vue';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useUserManager } from '~UserManager/hooks/useUserManager';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UserManager',
        components: {
            BaseAlert,
            LoadingWrapper,
            ModalButton,
            PageIntro,
            PageDescription,
            PageWrapper,
            BasicModal,
            ConfirmDeleteModal,
            ConfirmResetPasswordModal,
            AdminContainer,
            AddUserForm,
            EditUserForm,
            AgGridVue,
        },
        setup(props, context) {
            /** Debug environment flag. */
            const isDebug = process.env.NODE_ENV !== 'production';
            const manager = useUserManager();

            // ==== DEPENDENCY RESOLUTION ====
            provide('isDebug', isDebug);
            provide('UserManager', manager);

            // ==== COMPOSABLES ====
            const { store, constants, state, methods, alerts } = manager;
            const { isNARADisabled } = useNARAFeature();
            const { isSuperUser, isCurrentSubscriptionExpired } =
                useExpiredSubscriptionGate();

            // ==== CONSTANTS ====
            const defaultColDef = { ...constants.DefaultColumnDefinition };

            // ==== STATE ====
            const { targets, rowData, colDefs, users, accounts } = state;

            // ==== COMPUTED PROPERTIES ====
            const {
                isLoading,
                isAddModalOpen,
                isEditModalOpen,
                isDeleteModalOpen,
                isResetModalOpen,
            } = state;

            // ==== EVENTS ====
            const {
                onGridReady,
                onColumnResized,
                clickAddButton,
                cancelAdd,
                cancelEdit,
                cancelDelete,
                cancelReset,
                submitAdd,
                submitEdit,
                submitDelete,
                submitReset,
            } = methods;

            // ==== SETUP ====
            colDefs.value = methods.getColumnDefs();

            /** @type {{ enabled: Boolean, size: Number }} */
            const pagination = { enabled: true, size: 25 };

            const numUsers = computed(() => {
                return rowData.value?.length ?? 0;
            });

            const currentOrganization = computedEager(() => {
                return store?.state?.accounts?.organization;
            });

            const currentUser = computed(() => {
                return store.state?.users.me;
            });

            const currentSubscription = computedEager(() => {
                const organization = currentOrganization.value;
                return organization?.currentSubscription;
            });

            const pendingSubscription = computedEager(() => {
                const organization = currentOrganization.value;
                return organization?.pendingSubscription;
            });

            const maxUsers = computed(() => {
                return currentSubscription.value?.maxUsers ?? Infinity;
            });

            const maxUsersExceeded = computed(() => {
                return numUsers.value >= maxUsers.value;
            });

            const isAddUserDisabled = computed(() => {
                const isSuper = isSuperUser.value === true;
                const isCommercial = isNARADisabled.value === true;
                const isExpired =
                    isCommercial && isCurrentSubscriptionExpired.value;
                const isMaxUsersExceeded = maxUsersExceeded.value === true;
                const isDisabled = isMaxUsersExceeded || isExpired;

                // Handle resolution based on super admin status.
                return isSuper ? false : isDisabled;
            });

            // ==== EXPOSE ====
            return {
                isNARADisabled,
                // SERVICES
                alerts,
                // STATE
                users,
                accounts,
                userToAdd: targets.add,
                userToEdit: targets.edit,
                defaultColDef,
                rowData,
                colDefs,
                pagination,
                numUsers,
                maxUsers,
                // CONDITIONALS,
                isDebug,
                isLoading,
                isAddModalOpen,
                isEditModalOpen,
                isDeleteModalOpen,
                isResetModalOpen,
                maxUsersExceeded,
                isAddUserDisabled,
                // EVENTS
                onGridReady,
                onColumnResized,
                clickAddButton,
                cancelAdd,
                cancelEdit,
                cancelDelete,
                cancelReset,
                submitAdd,
                submitEdit,
                submitDelete,
                submitReset,
            };
        },
    });
</script>
